@import url("https://fonts.googleapis.com/css2?family=Raleway:wght@700&family=Roboto:wght@400;500&display=swap");
._3u-Jd {
  z-index: 99999;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  line-height: 1.375;
  color: #000; }
  ._3u-Jd *,
  ._3u-Jd :after,
  ._3u-Jd :before {
    box-sizing: border-box; }
  ._3u-Jd p,
  ._3u-Jd h1,
  ._3u-Jd h2,
  ._3u-Jd h3,
  ._3u-Jd h4,
  ._3u-Jd h5,
  ._3u-Jd h6 {
    margin-top: 0;
    margin-bottom: 16px; }
  ._3u-Jd a {
    color: #1877f2;
    text-decoration: none;
    background-color: transparent; }
    ._3u-Jd a:hover {
      text-decoration: underline; }
    ._3u-Jd a._3FAgV:hover {
      text-decoration: none; }
  ._3u-Jd ._2yhkQ {
    background-color: rgba(1, 17, 24, 0.75);
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0; }
  ._3u-Jd ._2A0LF {
    width: 100%;
    max-width: 568px;
    position: relative;
    padding: 0 14px; }
    ._3u-Jd ._2A0LF ._2o42J {
      position: absolute;
      right: 30px;
      top: 16px;
      background: unset;
      border: unset;
      padding: 0;
      cursor: pointer;
      display: inline-flex; }
    ._3u-Jd ._2A0LF ._1KkFT {
      background-color: #fff;
      border-radius: 8px;
      padding: 26px 26px;
      text-align: center; }
      ._3u-Jd ._2A0LF ._1KkFT ._12RKd {
        font-family: "Raleway", sans-serif;
        font-size: 24px;
        font-weight: 700; }
  @media (max-width: 576px) {
    ._3u-Jd ._2A0LF {
      padding: 0;
      max-width: none;
      height: 100%; }
      ._3u-Jd ._2A0LF ._2o42J {
        right: 16px; }
      ._3u-Jd ._2A0LF ._1KkFT {
        padding: 16px;
        border-radius: 0;
        height: 100%; }
        ._3u-Jd ._2A0LF ._1KkFT ._12RKd {
          font-size: 18px; } }

._3FAgV {
  font-size: 18px;
  font-weight: 700;
  font-family: "Raleway", sans-serif;
  color: #000;
  display: block;
  width: 100%;
  text-align: center;
  cursor: pointer;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  padding: 16px 30px;
  line-height: 1.4;
  border-radius: 4px;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  text-decoration: none; }
  ._3FAgV._AyiGt, ._3FAgV:focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(24, 119, 242, 0.25); }
  ._3FAgV._2NfuN, ._3FAgV:disabled {
    opacity: 0.65; }
  ._3FAgV:hover {
    text-decoration: none; }
  @media (max-width: 576px) {
    ._3FAgV {
      font-size: 16px; } }

._3wWJB {
  color: #fff;
  background-color: #1877f2;
  border-color: #1877f2; }
  ._3wWJB._AyiGt, ._3wWJB:focus, ._3wWJB:hover {
    color: #fff;
    background-color: #0c65d8;
    border-color: #0b5fcc; }
  ._3wWJB:not(:disabled):not(._2NfuN)._2ksEt, ._3wWJB:not(:disabled):not(._2NfuN):active {
    color: #fff;
    background-color: #0b5fcc;
    border-color: #0b5abf; }
  ._3wWJB._AyiGt, ._3wWJB:focus {
    box-shadow: 0 0 0 0.2rem rgba(59, 139, 244, 0.5); }

._3cCZB {
  color: #1877f2;
  border-color: #1877f2; }
  ._3cCZB:hover {
    color: #fff;
    background-color: #1877f2;
    border-color: #1877f2; }
  ._3cCZB._AyiGt, ._3cCZB:focus {
    box-shadow: 0 0 0 0.2rem rgba(24, 119, 242, 0.5); }
  ._3cCZB:not(:disabled):not(._2NfuN)._2ksEt, ._3cCZB:not(:disabled):not(._2NfuN):active {
    color: #fff;
    background-color: #1877f2;
    border-color: #1877f2; }
  ._3cCZB:not(:disabled):not(._2NfuN)._2ksEt:focus, ._3cCZB:not(:disabled):not(._2NfuN):active:focus {
    box-shadow: 0 0 0 0.2rem rgba(24, 119, 242, 0.5); }

._10wSV {
  display: inline-flex;
  align-items: center;
  justify-content: center; }
  ._10wSV ._1lALu {
    margin-right: 14px;
    display: inline-flex;
    align-items: center; }

._2qbfS {
  width: 24px;
  height: 24px;
  display: inline-block;
  -webkit-animation: _3pCqb 1.4s linear infinite;
          animation: _3pCqb 1.4s linear infinite; }
  ._2qbfS ._2GOts {
    display: block; }
    ._2qbfS ._2GOts ._2bHSf {
      -webkit-animation: _1zX2u 1.4s ease-in-out infinite;
              animation: _1zX2u 1.4s ease-in-out infinite;
      stroke: currentColor;
      stroke-dasharray: 80px, 200px;
      stroke-dashoffset: 0px; }

@-webkit-keyframes _3pCqb {
  100% {
    transform: rotate(360deg); } }

@keyframes _3pCqb {
  100% {
    transform: rotate(360deg); } }

@-webkit-keyframes _1zX2u {
  0% {
    stroke-dasharray: 1px, 200px;
    stroke-dashoffset: 0px; }
  50% {
    stroke-dasharray: 100px, 200px;
    stroke-dashoffset: -15px; }
  100% {
    stroke-dasharray: 100px, 200px;
    stroke-dashoffset: -125px; } }

@keyframes _1zX2u {
  0% {
    stroke-dasharray: 1px, 200px;
    stroke-dashoffset: 0px; }
  50% {
    stroke-dasharray: 100px, 200px;
    stroke-dashoffset: -15px; }
  100% {
    stroke-dasharray: 100px, 200px;
    stroke-dashoffset: -125px; } }

._MvwQ9 {
  font-weight: 500; }

._1UX0O {
  color: #1877f2; }

._2fTxK {
  min-height: 300px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #1877f2; }

._1O3P2 {
  margin: 32px 0 22px; }

._QHxWZ {
  margin-top: 32px; }

._2cJqM {
  margin-bottom: 25px; }

._3hzxD {
  margin-top: 24px; }

._19-BN {
  margin-bottom: 38px; }
  ._19-BN input {
    font-size: 18px;
    font-weight: 400;
    line-height: 1.375;
    background-color: transparent;
    background-clip: padding-box;
    border: unset;
    flex: 1 1 auto; }
    ._19-BN input:focus {
      outline: unset; }
  ._19-BN p._nFGF8 {
    text-align: left;
    margin: 8px 0 0 0;
    font-size: 14px;
    color: #585858; }
  ._19-BN._1ZSGf ._sx8q- {
    border-color: #ff3b30; }
  ._19-BN._1ZSGf ._nFGF8 {
    color: #ff3b30; }
  ._19-BN ._sx8q- {
    border: 1px solid #e5e5ea;
    border-radius: 4px;
    padding: 8px 14px;
    display: flex;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
    ._19-BN ._sx8q-:focus-within {
      border-color: #1877f2;
      background-color: #fff;
      border-color: #93c0fa;
      outline: 0;
      box-shadow: 0 0 0 3.2px rgba(25, 121, 245, 0.25); }
    ._19-BN ._sx8q- ._3oun- {
      background-color: #efeff4;
      border-radius: 4px;
      padding: 10px 12px;
      display: inline-flex;
      align-items: center;
      margin-right: 16px; }
      ._19-BN ._sx8q- ._3oun- ._3yM83 {
        margin-left: 8px; }
  @media (max-width: 576px) {
    ._19-BN {
      margin-bottom: 24px; }
      ._19-BN input {
        font-size: 14px; }
      ._19-BN p._nFGF8 {
        font-size: 12px; }
      ._19-BN ._sx8q- ._3oun- {
        font-size: 14px; } }

p._2cjG4 {
  font-size: 18px;
  margin-bottom: 36px; }

p._eIKSE {
  font-size: 16px;
  margin-bottom: 0; }

._2cC2n {
  margin-bottom: 38px; }

p._3-5Nx {
  margin-bottom: 0; }

._fFLqU {
  margin-bottom: 26px; }

@media (max-width: 576px) {
  p._2cjG4 {
    font-size: 14px;
    margin-bottom: 24px; }
  ._2cC2n {
    margin-bottom: 24px; }
  p._eIKSE {
    font-size: 13px; }
  ._2gkiW {
    font-size: 12px;
    color: #8e8e93; }
  p._3-5Nx {
    font-size: 14px; } }

._1JJFb {
  display: flex;
  justify-content: center;
  margin-bottom: 32px;
}

._1405w {
  position: relative;
}

._S5mn3 {
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
}

._S5mn3:focus {
  outline: unset;
}

._32LLq {
  display: inline-flex;
  align-items: center;
}

._33UYF {
  display: flex;
  position: relative;
  z-index: 1;
  background: #fff;
}

._2lG6r {
  min-width: 64px;
  max-width: 64px;
  height: 64px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #e5e5ea;
  font-size: 28px;
  font-weight: 700;
  text-align: center;
  color: #000000;
  position: relative;
  line-height: 1;
  border-radius: 4px;
}

._2lG6r:not(:last-child) {
  margin-right: 1rem;
}

._1405w:focus-within ._2lG6r._2rK9S,
._2hkvE > ._2lG6r._2rK9S {
  border: 2px solid #1877f2;
}

._1405w:focus-within ._2lG6r._2rK9S:empty::before,
._2hkvE > ._2lG6r._2rK9S:empty::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  width: 1px;
  height: 26px;
  background: #000;
  -webkit-animation: _SJwgm 1000ms step-end infinite;
  animation: _SJwgm 1000ms step-end infinite;
}

@keyframes _SJwgm {
  from,
  to {
    opacity: 1;
  }

  50% {
    opacity: 0;
  }
}

@-webkit-keyframes _SJwgm {
  from,
  to {
    opacity: 1;
  }

  50% {
    opacity: 0;
  }
}

._3W1mG > ._2lG6r {
  border: 1px solid #ff3b30;
}

._2H21F > ._2lG6r {
}

._166d4 {
  display: inline-flex;
  margin-left: 1rem;
}

p._1kBh9 {
  margin-top: 0.75rem;
  margin-bottom: 0;
  font-size: 0.875rem;
  color: #ff3b30;
}

._SVghP {
  margin-top: 32px;
}

._17KNo {
  margin: 40px 0;
}

hr {
  margin: 24px 0;
  border: 1px dashed #E5E5EA;
}

._35Q2- {
  display: flex;
  justify-content: space-between;
  margin-bottom: 12px;
}

._35Q2- p {
  margin: 0;
}

._3oZVL {
  margin-top: 16px;
  color: #1877f2;
  padding: 8px 30px;
}

._3oZVL:hover {
  color: #0c65d8;
}
._3EZbr {
  display: flex;
  justify-content: center;
  margin: 32px 0 38px;
  position: relative;
}

._1u2NB {
  position: relative;
}

._1G_Jo {
  position: absolute;
  top: 0;
  left: 0;
  width: 1px;
  opacity: 0;
}

._1G_Jo:focus {
  outline: unset;
}

._1QHbm {
  display: inline-flex;
  align-items: center;
  position: relative;
}

._3fBPJ {
  display: flex;
  position: relative;
  z-index: 1;
  background: #fff;
}

._28M2X {
  height: 64px;
  display: flex;
  align-items: center;
  justify-content: center;
}

._28M2X ._FyGqB {
  width: 12px;
  height: 2px;
  border-radius: 50px;
  background-color: #8e8e93;
  transition: background-color 150ms ease-in-out, height 150ms ease-in-out;
}

._28M2X ._3Wuol {
  font-size: 24px;
  font-weight: 500;
  border-bottom: 2px solid #8e8e93;
  min-width: 30px;
  min-height: 42px;
  display: none;
}

._28M2X:not(:last-child) {
  margin-right: 16px;
}

._1u2NB:focus-within ._28M2X._2PVQh:not(._M8Pjz) ._FyGqB,
._3OYxL>._28M2X._2PVQh:not(._M8Pjz) ._FyGqB {
  background-color: #1877f2;
}

._1u2NB:focus-within ._28M2X._2PVQh:not(._M8Pjz) ._3Wuol,
._3OYxL>._28M2X._2PVQh:not(._M8Pjz) ._3Wuol {
  border-color: #1877f2;
}

._28M2X._M8Pjz ._FyGqB {
  height: 12px;
  background-color: #000;
}

._28M2X._M8Pjz ._3Wuol {
  border-color: #000;
}

._d0wbO>._28M2X ._FyGqB {
  background-color: #ff3b30;
}

._d0wbO>._28M2X ._3Wuol {
  border-color: #ff3b30;
}

._wFfDw>._28M2X {}

._1xxwd {
  display: inline-flex;
  margin-left: 16px;
}

p._3RwaB {
  margin-top: 12px;
  margin-bottom: 0;
  font-size: 14px;
  color: #ff3b30;
}

._3H9lv ._28M2X:not(:last-child) {
  margin-right: 10px;
}

._3H9lv ._28M2X ._FyGqB {
  display: none;
}

._3H9lv ._28M2X ._3Wuol {
  display: inline-block
}

._42zTn {
  position: absolute;
  z-index: 1;
  background: unset;
  padding: 4px;
  border: unset;
  display: inline-flex;
  left: 100%;
  top: 16px;
  color: #000;
  opacity: .5;
  transition: opacity 120ms ease-in-out;
  cursor: pointer;
  margin-left: 16px;
}

._42zTn:hover {
  opacity: .9;
}
